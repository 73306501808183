import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, interval, Observable, share, throttle } from 'rxjs';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { NotificationService } from '../notification.service';
import { UserService } from '../user.service';
import {
  MESSAGE_TYPES,
  WebsocketService,
  WsMessage,
} from '../websocket.service';
import { logIt } from '../utils';

export interface ChatMessage {
  room: string;
  from: string;
  message: string;
  ts: number;
}

export type WelcomeMessage = Record<string, any>;

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private userName: string = '';
  public readonly welcomeMessages$: Observable<WelcomeMessage>;
  public chatMessages$: Observable<ChatMessage>;

  constructor(
    private router: Router,
    private userService: UserService,
    private toaster: NotificationService,
    private wsService: WebsocketService
  ) {
    this.userService.userName$.subscribe((un) => (this.userName = un));
    this.chatMessages$ = wsService.messages$.pipe(
      filter((msg) => msg.action === MESSAGE_TYPES.CHAT_MESSAGE),
      map((msg: WsMessage) => msg.data as ChatMessage),
      shareReplay(100)
    );
    this.welcomeMessages$ = wsService.messages$.pipe(
      filter((msg) => msg.action === MESSAGE_TYPES.WELCOME),
      map((msg: WsMessage) => msg.data as WelcomeMessage),
      shareReplay(1)
    );
    this.chatMessages$
      .pipe(
        tap((msg) => {
          logIt('info', 'chatMessages$', msg);
        }),
        throttle((ev) => interval(5000))
      )
      .subscribe((msg) => {
        if (
          router.url !== '/chat' &&
          msg.from !== 'SYSTEM' &&
          msg.from !== this.userName
        ) {
          // if (msg.from !== 'SYSTEM') {
          this.toaster.default(`${msg.from}: ${msg.message}`, 5000, {
            horizontalPosition: 'right',
          });
        }
      });
    this.welcomeMessages$.subscribe((msg) => {});
    this.wsService.socketConnected$.pipe(share()).subscribe((isConnected) => {
      if (isConnected) {
        this.sendHello();
      }
    });
  }

  private sendHello(): void {
    logIt('info', 'sendingHello start');
    combineLatest([this.userService.userName$])
      .pipe(
        map(([name]) => {
          return {
            status: true,
            action: MESSAGE_TYPES.HELLO,
            data: {
              name,
            },
          };
        }),
        filter((msg) => !!msg.data.name),
        take(1)
      )
      .subscribe((msg) => {
        logIt('info', 'sendingHello', msg);
        this.wsService.sendMessage(msg);
      });
  }

  sendChatMessage(msg: string) {
    if (!this.userName)
      throw new Error('Send chat message called before userName set');
    this.wsService.sendMessage({
      status: true,
      action: MESSAGE_TYPES.CHAT_MESSAGE,
      data: {
        room: '',
        from: this.userName,
        message: msg,
        ts: Date.now(),
      },
    });
  }
}
