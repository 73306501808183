import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ReportsService } from '../reports.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.scss'],
})
export class DebugInfoComponent implements OnInit {
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public reportService: ReportsService
  ) {}

  ngOnInit(): void {
    this.reportService.allReports();
  }
}
