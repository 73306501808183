import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment as env } from '../environments/environment';
import { IAjaxResponse } from './app-interfaces';
import { NotificationService } from './notification.service';

// all logging should go through this, so it can be redirected if needed
export const logIt = (
  level: 'info' | 'warn' | 'error',
  ...args: any[]
): void => {
  if (env.production) {
    console[level](...args);
  } else {
    try {
      let anyObjects = false;
      for (const a of args) {
        if (typeof a === 'object') {
          anyObjects = true;
        }
      }
      if (anyObjects) {
        console[level](
          args.length > 1
            ? JSON.stringify(args, null, 2)
            : JSON.stringify(args[0], null, 2)
        );
      } else {
        console[level](...args);
      }
    } catch (ex) {
      console[level](...args);
    }
  }
};

// remove \r and replace \n with <br/>
export const nl2br = (str: string): string => {
  return (str || '').trim().replace(/\r?\n/g, '<br/>');
};

export const handleHttpError2 = <T>(
  err: HttpErrorResponse
): Observable<IAjaxResponse<T>> => {
  if (err.error instanceof Error) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', err.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${err.status}, body was: ${err.error}`
    );
    console.error(err);
  }
  return of({ status: false, data: null, message: err.message || 'error' });
};

export const handleHttpError = (
  err: HttpErrorResponse
): Observable<IAjaxResponse<null>> => {
  if (err.error instanceof Error) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', err.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${err.status}, body was: ${err.error}`
    );
    console.error(err);
  }
  return of({ status: false, data: null, message: err.message || 'error' });
};

// converts ajax response to provided data type or null
// if !response.success and result.message show error toast with message
export const handleErrorResponse = <T>(
  results: IAjaxResponse<T>,
  toaster?: NotificationService,
  showError: boolean = true
): T | null => {
  if (!results.status && results.message && toaster && showError) {
    toaster.error(results.message);
  }
  return results.data || null;
};

export const handleResponse = <T>(
  results: IAjaxResponse<T>,
  toaster: NotificationService
): T | null => {
  if (!results.message) {
    if (results.status) {
      results.message = 'success';
    } else {
      results.message = 'error';
    }
  }
  if (results.status) {
    toaster.success(results.message, 5000);
  } else {
    toaster.error(results.message, 5000);
  }
  return results.data || null;
};

export const copyToClipboard = (str: string): void => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  let sel = document.getSelection();
  const selected = sel && sel.rangeCount > 0 ? sel.getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    sel = document.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(selected);
    }
  }
};

export const hasPopupBlocker = (): boolean => {
  return false;
  // try {
  //   const test = window.open('about:blank', '_blank', 'width=100,height=100');
  //   if (!test) throw new Error('Blocked');
  //   test.close();
  //   return false;
  // } catch (e) {
  //   return true;
  // }
};

export const downloadUrl = (url: string): void => {
  if (!url) {
    return;
  }
  window.open(url, '_blank');
};
