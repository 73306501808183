import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { ChatComponent } from './chat/chat.component';
import { DebugInfoComponent } from './debug-info/debug-info.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReportingMenuComponent } from './reporting-menu/reporting-menu.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportingMenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fuel_upload',
    loadChildren: () =>
      import('./fuel-upload/fuel-upload.module').then(
        (m) => m.FuelUploadModule
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'chat',
    // loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    component: ChatComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'debug',
    component: DebugInfoComponent,
  },
  {
    path: 'denied',
    component: AccessDeniedComponent,
    data: { title: 'Access Denied' },
  },
  {
    path: '404/:message',
    component: PageNotFoundComponent,
    data: { title: 'Page Not Found' },
  },

  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Page Not Found' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
