import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat.component';
import { logIt } from '../utils';

const routes: Routes = [{ path: '', component: ChatComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChatRoutingModule {
  constructor() {
    logIt('info', 'ChatRoutingModule');
  }
}
