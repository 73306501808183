import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { logIt } from './utils';
import { environment as env } from '../environments/environment';

export interface IPushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  data?: any;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: 'auto' | 'ltr' | 'rtl';
  lang?: string;
  vibrate?: number[];
}

export interface INotifyEvent {
  notification: Notification;
  eventType: 'show' | 'click' | 'error';
  event: Event;
}

export interface IHasToaster {
  toaster: NotificationService;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private pushNotificationMap = new Map();
  private permission: NotificationPermission = 'denied';

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {
    this.permission = this.isSupported() ? Notification.permission : 'denied';
    if (this.permission !== 'granted') {
      this.requestPermission();
    }
  }

  isSupported(): boolean {
    return 'Notification' in window;
  }

  requestPermission(): void {
    if (this.isSupported()) {
      try {
        Notification.requestPermission()
          .then((status: NotificationPermission) => (this.permission = status))
          .catch(() => (this.permission = 'denied'));
      } catch (ignored: any) {}
    }
  }

  createPopup(
    title: string,
    options?: NotificationOptions
  ): Observable<INotifyEvent> {
    return new Observable((obs: any) => {
      if (!this.isSupported()) {
        obs.error('Notifications are not available in this environment');
        obs.complete();
      }

      if (this.permission !== 'granted') {
        obs.error(
          `The user hasn't granted you permission to send push notifications`
        );
        obs.complete();
      }

      const n = new Notification(title, options);

      n.onshow = (e: Event) =>
        obs.next({ notification: n, eventType: 'show', event: e });
      n.onclick = (e: Event) =>
        obs.next({ notification: n, eventType: 'click', event: e });
      n.onerror = (e: Event) =>
        obs.error({ notification: n, eventType: 'error', event: e });
      n.onclose = () => obs.complete();
    });
  }

  default(
    message: string,
    duration: number = 2000,
    config: MatSnackBarConfig = {}
  ): void {
    logIt('info', 'NotificationService', message);
    this.show(message, {
      ...config,
      duration: duration,
      panelClass: 'default-notification-overlay',
    });
  }

  info(
    message: string,
    duration: number = 2000,
    config: MatSnackBarConfig = {}
  ): void {
    logIt('info', 'NotificationService', message);
    this.show(message, {
      ...config,
      duration: duration,
      panelClass: 'info-notification-overlay',
    });
  }

  success(
    message: string,
    duration: number = 2000,
    config: MatSnackBarConfig = {}
  ): void {
    logIt('info', 'NotificationService', message);
    this.show(message, {
      ...config,
      duration: duration,
      panelClass: 'success-notification-overlay',
    });
  }

  warn(
    message: string,
    duration: number = 2500,
    config: MatSnackBarConfig = {}
  ): void {
    logIt('warn', 'NotificationService', message);
    this.show(message, {
      ...config,
      politeness: 'assertive',
      duration: duration,
      panelClass: 'warning-notification-overlay',
    });
  }

  error(
    message: string,
    duration: number = 5000,
    config: MatSnackBarConfig = {}
  ): void {
    logIt('error', 'NotificationService', message);
    this.show(message, {
      ...config,
      politeness: 'assertive',
      duration: duration,
      panelClass: 'error-notification-overlay',
    });
  }

  public desktop(body: string, tag?: string): Observable<INotifyEvent> {
    const n = this.createPopup(env.appName, {
      icon: '/assets/favicon/mstile-144x144.png',
      body,
      tag,
    });
    if (tag) {
      this.pushNotificationMap.set(tag, n.subscribe());
    }
    return n;
  }

  public removeDesktop(tag: string): boolean {
    const n = this.pushNotificationMap.get(tag);
    if (!n) {
      return false;
    }
    if (typeof n.close === 'function') {
      n.close();
    }
    return this.pushNotificationMap.delete(tag);
  }

  public clearDesktop(): void {
    this.pushNotificationMap.forEach((k, v) => {
      if (v && typeof v.close === 'function') {
        v.close();
      }
    });
    this.pushNotificationMap.clear();
  }

  private show(message: string, configuration: MatSnackBarConfig): void {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, undefined, configuration));
  }
}
