import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { dayjs } from '../shared/date-util';
import { ReportsService } from '../reports.service';
import { AjaxBusyNotifierService } from '../shared/busy-identifier/ajax-busy-notifier.service';

@Component({
  selector: 'app-reporting-menu-page',
  templateUrl: './reporting-menu.component.html',
  styleUrls: ['./reporting-menu.component.scss'],
})
export class ReportingMenuComponent implements OnInit {
  reportForm: UntypedFormGroup;
  reports: Observable<string[]> = of(['XTD', 'ETL']);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public abns: AjaxBusyNotifierService,
    public reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    const start_date = dayjs().subtract(90, 'days');
    const end_Date = dayjs().subtract(1, 'days');
    this.reportForm = this.formBuilder.group({
      start_date: [start_date.toDate(), [Validators.required]],
      end_date: [end_Date.toDate(), Validators.required],
    });
  }

  public submit(reportName: string) {
    if (!this.reportForm.valid) {
      return;
    }
    const start_date = dayjs.utc(
      dayjs(this.reportForm.controls.start_date.value).format('YYYY-MM-DD')
    );
    const end_date = dayjs.utc(
      dayjs(this.reportForm.controls.end_date.value).format('YYYY-MM-DD')
    );
    this.reportsService.dateRangeReport(reportName, start_date, end_date);
  }
}
