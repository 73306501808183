<div class="container-with-top-centered-content">
  <div #chatList class="chat-list">
    <div *ngFor="let msg of messages">
      <div class="msg-ts" [innerHtml]="msg.ts | date: 'hh:mma'"></div>
      <div class="msg-from" [innerHtml]="msg.from | nl2br"></div>
      <div class="msg-message" [innerHtml]="msg.message | nl2br"></div>
    </div>
  </div>
  <div class="send-message">
    <form id="chatForm" [formGroup]="chatForm">
      <mat-form-field appearance="fill" class="send-message-input">
        <mat-label>Msg</mat-label>
        <input
          #chatInput
          matInput
          formControlName="sendMessage"
          autocomplete="off"
        />
      </mat-form-field>
      <button
        [disabled]="!chatForm.valid"
        (click)="sendMsg()"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Send
      </button>
    </form>
  </div>
</div>
