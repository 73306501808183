import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first, takeWhile } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NotificationService } from './notification.service';
import { logIt } from './utils';

@Injectable()
export class CheckForUpdateService {
  constructor(
    appRef: ApplicationRef,
    updates: SwUpdate,
    notificationService: NotificationService
  ) {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));
    const everyInterval$ = interval(15 * 60 * 1000);
    const everyIntervalOnceAppIsStable$ = concat(appIsStable$, everyInterval$);
    everyIntervalOnceAppIsStable$
      .pipe(takeWhile(() => environment.production))
      .subscribe(() => {
        updates
          .checkForUpdate()
          .catch(() =>
            logIt('warn', 'CheckForUpdateService not supported in this browser')
          );
      });

    updates.available.subscribe((event) => {
      logIt('info', 'current version is', event.current);
      logIt('info', 'available version is', event.available);
      updates.activateUpdate().then(() => {
        notificationService
          .desktop(
            'New Version Available\nClick to reload page.',
            'new_version'
          )
          .subscribe((ne) => {
            document.location.reload();
          });
      });
    });
    updates.activated.subscribe((event) => {
      logIt('info', 'old version was', event.previous);
      logIt('info', 'new version is', event.current);
    });
    updates.unrecoverable.subscribe((event) => {
      notificationService.error(
        `An error occurred that we cannot recover from:\n${event.reason}\n\n` +
          'Reload page in 5 seconds.'
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });
  }
}
