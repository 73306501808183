<div class="container-with-top-centered-content">
  <br />
  <h1>Welcome to the Self Serve Data Portal</h1>
  <br />
  <br />
  <p class="warning-notification-overlay">
    Please make sure you allow popups from this site or report downloads may
    fail.
  </p>
  <h2>Please read before clicking Login button!</h2>
  <ul>
    <li>
      On the login page please select the button that corresponds with the
      domain of the login you will be using.<br />
      For example, if you wish to use a Red Wing Alliance login select the
      “Continue with RWA” option.<br />
    </li>
    <li>
      You will then be redirected to a Microsoft login page where you can
      login.<br />
    </li>
    <li>
      On first login you will also be prompted to setup DUO MFA to access this
      site.<br />
      When prompted to enroll in DUO, do not enter a phone number and select "I
      have a tablet" option, otherwise DUO will say your phone number is already
      registered and can’t be used.<br />
    </li>
    <li>
      After you complete the DUO process you will be prompted to allow access to
      your profile and email address, accept and continue.<br />
    </li>
    <li>
      After your first login you may request access to the fuel module if needed
      <a
        class="mat-raised-button"
        href="mailto:kanderson@xojetaviation.com?cc=probello@xojetaviation.com&subject=Requesting access to data portal fuel module"
        >Email Kurt Anderson and CC Paul Robello.</a
      ><br />
    </li>
    <li>
      Once access is granted logout and back into portal, fuel related items
      will then appear.<br />
    </li>
  </ul>
  <br />
  <br />
  <p>
    If you get stuck in a login loop, try the
    <a mat-flat-button href="/logout">Force Logout</a> button then login again.
  </p>
  <button
    *ngIf="
      !(
        (authService.isLoading$ | async) ||
        (authService.isAuthenticated$ | async)
      )
    "
    mat-flat-button
    color="primary"
    (click)="authService.loginWithRedirect()"
  >
    Login
  </button>
</div>
