import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { ChatService } from './chat/chat.service';
import { CheckForUpdateService } from './check-for-update.service';
import { NotificationService } from './notification.service';
import { AjaxBusyNotifierService } from './shared/busy-identifier/ajax-busy-notifier.service';
import { TitleService } from './title.service';
import { WebsocketService } from './websocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Data Portal';

  constructor(
    public abns: AjaxBusyNotifierService,
    private toaster: NotificationService,
    private titleService: TitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    // these services need to be created even though they are not referenced here, so they can begin working on things.
    private checkForUpdateService: CheckForUpdateService,
    private authService: AuthService,
    private wsService: WebsocketService
  ) {}
  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot)
      )
      .subscribe((snapshot) => {
        this.titleService.setTitle(snapshot);
      });
  }
}
