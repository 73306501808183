<div class="pad-5">
  <h3>Identity Claims</h3>
  <pre>
      {{ authService.idTokenClaims$ | async | json }}
    </pre
  >
  <hr />
  <h3>User Claims</h3>
  <pre>
    {{ authService.user$ | async | json }}
    </pre
  >
  <hr />
  <h3>Authorization Claims</h3>
  <pre>
    {{ userService.accessToken | json }}
    </pre
  >
  <hr />
  <h3>Protected list of reports</h3>
  <pre>
    {{ reportService.reports$ | async | json }}
  </pre>
</div>
