import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from '../notification.service';
import { ChatMessage, ChatService } from './chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('chatList', { read: ElementRef })
  private myScrollContainer: ElementRef;
  @ViewChild('chatInput', { read: ElementRef }) private myChatInput: ElementRef;
  messages: ChatMessage[] = [
    { room: '', from: 'LOCAL', message: 'Connecting...', ts: Date.now() },
  ];
  chatForm: UntypedFormGroup;
  messages$ = this.chatService.chatMessages$.subscribe((msg: ChatMessage) => {
    this.messages.push(msg);
    while (this.messages.length > 1000) {
      this.messages.shift();
    }
    setTimeout(() => this.scrollToBottom(), 100);
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toaster: NotificationService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.chatForm = this.formBuilder.group({
      sendMessage: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    setTimeout(() => this.myChatInput.nativeElement.focus());
  }

  ngAfterViewChecked() {
    // this.scrollToBottom();
  }

  public sendMsg() {
    // this.toaster.success(this.chatForm.controls.sendMessage.value);
    this.chatService.sendChatMessage(this.chatForm.controls.sendMessage.value);
    this.chatForm.controls.sendMessage.setValue('');
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (ignored) {}
  }
}
