<div class="container-with-top-centered-content">
  <form [formGroup]="reportForm">
    <h1>Reports</h1>
    <br />
    <br />
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="Start date"
          formControlName="start_date"
        />
        <input matEndDate placeholder="End date" formControlName="end_date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker startView="month"></mat-date-range-picker>
      <mat-error
        *ngIf="reportForm.controls.start_date.hasError('matStartDateInvalid')"
        >Invalid start date</mat-error
      >
      <mat-error
        *ngIf="reportForm.controls.end_date.hasError('matEndDateInvalid')"
        >Invalid end date</mat-error
      >
    </mat-form-field>

    <div class="report-button-row">
      <button
        *ngFor="let report of reports | async"
        [disabled]="!reportForm.valid || (abns.isBusy$ | async)"
        (click)="submit(report.toLocaleLowerCase())"
        mat-raised-button
        color="primary"
        type="submit"
      >
        {{ report }}
      </button>
    </div>
  </form>
</div>
