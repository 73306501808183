import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { logIt } from './utils';

export interface Auth0AccessTokenPayload extends JwtPayload {
  claims: string[];
  permissions: string[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public accessToken: Auth0AccessTokenPayload | null;
  public accessTokenRaw: string | null;
  private readonly accessTokenSub$: Subject<Auth0AccessTokenPayload | null> =
    new Subject<Auth0AccessTokenPayload | null>();
  private readonly accessTokenRawSub$: Subject<string | null> = new Subject<
    string | null
  >();
  public readonly accessToken$: Observable<Auth0AccessTokenPayload | null>;
  public readonly accessTokenRaw$: Observable<string | null>;
  public readonly userName$: Observable<string>;

  constructor(private authService: AuthService) {
    this.authService.appState$.subscribe((s) => logIt('info', { appState: s }));
    this.authService.idTokenClaims$.subscribe((s) =>
      logIt('info', { idTokenClaims: s })
    );
    this.accessToken$ = this.accessTokenSub$
      .asObservable()
      .pipe(shareReplay(1));
    this.accessTokenRaw$ = this.accessTokenRawSub$
      .asObservable()
      .pipe(shareReplay(1));
    this.accessTokenRaw$.subscribe();
    this.userName$ = this.authService.user$.pipe(
      map((u) => u?.given_name || '?'),
      distinctUntilChanged(),
      shareReplay(1)
    );
    this.userName$.subscribe((un) => logIt('info', 'username$', un));
    this.authService
      .getAccessTokenSilently({ audience: env.accessTokenAudience })
      .pipe(
        catchError((e) => of(null)),
        tap((token) => {
          this.accessTokenRaw = token ? 'Bearer ' + token : null;
          this.accessTokenRawSub$.next(this.accessTokenRaw);
        }),
        map((token) => {
          return token ? (jwt_decode(token) as Auth0AccessTokenPayload) : null;
        })
      )
      .subscribe((accessToken) => {
        logIt('info', { accessToken });
        this.accessToken = accessToken;
        this.accessTokenSub$.next(accessToken);
      });
  }
}
