export const environment = {
  "production": true,
  "appName": "Data Portal",
  "apiBase": "https://dp.prod.ea.xojetaviation.com/api",
  "wsEndpoint": "wss://dp.prod.ea.xojetaviation.com/ws/",
  "accessTokenAudience": "xoa_data_portal",
  "auth0Domain": "https://auth0.prod.ea.vistajet.com",
  "auth0ClientId": "ciO35DPpH9eayFQ6Nwg6wIflEF5Uha2x",
  "reconnectInterval": 10000,
  "scopes": [
    "xoa_data_portal/reports.run",
    "xoa_data_portal/fuel_price.update"
  ],
  "version": 1.1
};