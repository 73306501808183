import { Injectable } from '@angular/core';
import { Dayjs } from './date-util';
import { Observable, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ClockService {
  public readonly heartbeatInterval$: Observable<[Dayjs, number]>;

  constructor() {
    this.heartbeatInterval$ = timer(0, 1000).pipe(
      map<number, [Dayjs, number]>((index) => [dayjs().utc(), index]),
      shareReplay(1)
    );
  }
}
