import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GravatarModule } from 'ngx-gravatar';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment as env } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { ChatModule } from './chat/chat.module';
import { AppErrorHandler } from './error-handler.service';
import { NavComponent } from './nav/nav.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReportingMenuComponent } from './reporting-menu/reporting-menu.component';
import { HomeComponent } from './home/home.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { ClockService } from './shared/clock.service';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { SharedModule } from './shared/shared.module';
import { TaskService } from './task.service';
import { TitleService } from './title.service';
import { LogoutComponent } from './logout/logout.component';
import { DebugInfoComponent } from './debug-info/debug-info.component';
import { UserService } from './user.service';
import { WebsocketService } from './websocket.service';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ReportingMenuComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    HomeComponent,
    PageNotFoundComponent,
    AccessDeniedComponent,
    LogoutComponent,
    DebugInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    ChatModule,
    GravatarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: env.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    HttpClientModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: env.auth0Domain,
      clientId: env.auth0ClientId,
      redirectUri: window.location.origin,
      audience: env.accessTokenAudience,
      httpInterceptor: {
        allowedList: [
          // Attach access tokens to any calls to '/api' (exact match)
          env.apiBase,
          // Attach access tokens to any calls that start with '/api/'
          `${env.apiBase}/*`,
        ],
      },
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    TitleService,
    AuthService,
    UserService,
    ClockService,
    WebsocketService,
    TaskService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
