import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { MatDateFormats } from '@angular/material/core';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

dayjs.locale('en');

// export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
//   parse: {
//     dateInput: 'MM/DD/YYYY'
//   },
//   display: {
//     dateInput: 'MM/DD/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY'
//   }
// };

export {
  dayjs,
  Dayjs,
  utc,
  timezone,
  localizedFormat,
  advancedFormat,
  customParseFormat,
  isBetween,
  isYesterday,
  isToday,
  isTomorrow,
  relativeTime,
};
