import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, map, shareReplay, take } from 'rxjs/operators';
import { IAjaxResponse } from './app-interfaces';
import { Dayjs } from './shared/date-util';
import { Observable, of } from 'rxjs';
import { NotificationService } from './notification.service';
import { environment as env } from '../environments/environment';
import { AjaxBusyNotifierService } from './shared/busy-identifier/ajax-busy-notifier.service';
import {
  downloadUrl,
  handleErrorResponse,
  handleHttpError,
  hasPopupBlocker,
} from './utils';

export type Report = string;

export type ReportList = Report[];

export type IReportDownloadResponse = string;

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public reports$: Observable<ReportList>;

  constructor(
    private http: HttpClient,
    private abns: AjaxBusyNotifierService,
    private toaster: NotificationService
  ) {
    if (hasPopupBlocker()) {
      toaster.error('Please allow popups');
    }
  }

  public allReports(): Observable<ReportList> {
    this.reports$ = this.http
      .get<IAjaxResponse<ReportList>>(`${env.apiBase}/reports/`)
      .pipe(
        catchError((err) => handleHttpError(err)),
        map((res) => handleErrorResponse(res, this.toaster)),
        map((res: ReportList | null) => res || []),
        take(1),
        shareReplay(1)
      );
    return this.reports$;
  }

  public dateRangeReport(
    reportName: string,
    start_date: Dayjs,
    end_date: Dayjs
  ): void {
    const url = `${
      env.apiBase
    }/download/?report=${reportName}&mode=url&start_date=${start_date.format(
      'YYYY-MM-DD'
    )}&end_date=${end_date.format('YYYY-MM-DD')}`;
    this.abns.setBusy(true);
    this.http
      .get<IAjaxResponse<IReportDownloadResponse>>(url)
      .pipe(
        catchError((err) => handleHttpError(err)),
        map((res) => handleErrorResponse(res, this.toaster)),
        take(1),
        map((res) => res || ''),
        finalize(() => this.abns.setBusy(false))
      )
      .subscribe((url) => downloadUrl(url));
  }
}
