import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AjaxBusyNotifierService {
  private readonly busy$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly isBusy$: Observable<boolean> = this.busy$
    .asObservable()
    .pipe(shareReplay(1));

  constructor() {}

  public setBusy(value: boolean) {
    this.busy$.next(value);
  }
}
