import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { ChatService } from './chat.service';

@NgModule({
  declarations: [ChatComponent],
  imports: [CommonModule, ChatRoutingModule, SharedModule],
  providers: [ChatService],
})
export class ChatModule {
  constructor(private ChatService: ChatService) {}
}
