import { Pipe, PipeTransform } from '@angular/core';
import { nl2br } from '../utils';

@Pipe({
  name: 'nl2br',
})
export class Nl2brPipe implements PipeTransform {
  transform(value: string): string {
    return nl2br(value);
  }
}
