import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment as env } from '../../environments/environment';
import { Auth0AccessTokenPayload, UserService } from '../user.service';

export interface MenuItem {
  route: string;
  label: string;
  permission?: string | Array<string>;
  divider_top?: boolean;
  divider_bottom?: boolean;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements AfterViewInit {
  @ViewChild('sidenavContainer', { static: false })
  sideNavContainer: MatSidenavContainer;
  @ViewChild('drawer', { static: false })
  sideNav: MatSidenav;
  private isHandset: boolean;
  appName = env.appName;
  version = env.version;
  menuItems: MenuItem[] = [
    { route: '/home', label: 'Home' },
    { route: '/chat', label: 'Chat', permission: 'chat.user' },
    { route: '/reports', label: 'Reports', permission: 'reports.run' },
    {
      route: '/fuel_upload',
      label: 'Fuel Upload',
      permission: 'fuel_price.update',
    },
    {
      route: '/fuel_upload/data',
      label: 'Fuel Pricing',
      permission: ['fuel_price.read', 'fuel_price.update'],
    },
    {
      route: '/logout',
      label: 'Logout',
      permission: 'authenticated',
      divider_top: true,
    },
  ];

  menuItems$: Observable<MenuItem[]>;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      tap((handset) => (this.isHandset = handset)),
      shareReplay(1)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public userService: UserService,
    public authService: AuthService
  ) {
    if (!env.production) {
      this.menuItems.push({ route: '/debug', label: 'Debug' });
    }

    this.menuItems$ = combineLatest([
      of(this.menuItems),
      userService.accessToken$,
    ]).pipe(
      map<[MenuItem[], Auth0AccessTokenPayload | null], MenuItem[]>(
        ([m, t]) => {
          return m.filter((mi: MenuItem) => {
            if (!mi.permission) return true;
            if (mi.permission === 'authenticated' && t) return true;
            if (!t) return false;
            if (Array.isArray(mi.permission)) {
              let tmpBool: boolean = false;
              mi.permission.forEach((v, i) => {
                if (t.permissions.includes(v)) {
                  tmpBool = true;
                }
              });
              return tmpBool;
            } else {
              return t.permissions.includes(mi.permission);
            }
          });
        }
      )
    );
    // this.menuItems$ = of(this.menuItems);
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.sideNavContainer.updateContentMargins();
    //   this.sideNav.open();
    // }, 250);
  }

  closeDrawer(drawer: MatSidenav) {
    if (this.isHandset) drawer.close();
  }
}
