<mat-sidenav-container
  class="sidenav-container"
  [hasBackdrop]="isHandset$ | async"
  style="position: unset"
  #sidenavContainer
>
  <!-- dialog / navigation-->
  <!-- over / side-->
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'dialog'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        *ngFor="let item of menuItems$ | async"
        [routerLink]="item.route"
        (click)="closeDrawer(drawer)"
      >
        {{ item.label }}
        <mat-divider
          *ngIf="item.divider_top"
          [inset]="true"
          class="mat-divider-top"
        ></mat-divider>
        <mat-divider *ngIf="item.divider_bottom" [inset]="true"></mat-divider>
      </a>
    </mat-nav-list>

    <button
      *ngIf="
        drawer.opened &&
        !(isHandset$ | async) &&
        (authService.isAuthenticated$ | async)
      "
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      class="nav-toggle-inside"
    >
      <mat-icon *ngIf="!drawer.opened" aria-label="Side nav toggle icon"
        >chevron_right</mat-icon
      >
      <mat-icon aria-label="Side nav toggle icon">chevron_left</mat-icon>
    </button>
  </mat-sidenav>

  <mat-sidenav-content
    [ngClass]="{ 'mat-drawer-container-opened': drawer.opened }"
  >
    <button
      *ngIf="
        !drawer.opened &&
        !(isHandset$ | async) &&
        (authService.isAuthenticated$ | async)
      "
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      class="nav-toggle-outside"
    >
      <mat-icon aria-label="Side nav toggle icon">chevron_right</mat-icon>
    </button>

    <mat-toolbar color="primary">
      <button
        *ngIf="(isHandset$ | async) && (authService.isAuthenticated$ | async)"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <!-- *ngIf="isHandset$ | async" -->
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="text-shadow-1x">{{ appName }} V{{ version }}</span>
      <div class="spacer">&nbsp;</div>
      <span
        *ngIf="authService.isAuthenticated$ | async"
        style="margin-right: 5px"
        >{{ (authService.user$ | async)?.given_name }}</span
      >
      <span *ngIf="authService.isAuthenticated$ | async">
        <img
          ngxGravatar
          class="text-shadow-1x"
          [title]="(authService.user$ | async)?.email || ''"
          [email]="(authService.user$ | async)?.email || ''"
          [size]="30"
          [round]="true"
          borderColor="#FFF"
          [borderWidth]="2"
        />
      </span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
