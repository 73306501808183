import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AjaxBusyNotifierService } from './ajax-busy-notifier.service';

@Injectable({
  providedIn: 'root',
})
export class AjaxBusyIdentifierInterceptorService implements HttpInterceptor {
  constructor(private abns: AjaxBusyNotifierService) {}

  requestCounter = 0;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.beginRequest();
    return next.handle(req).pipe(
      finalize(() => {
        this.endRequest();
      })
    );
  }

  beginRequest(): void {
    this.requestCounter = Math.max(this.requestCounter, 0) + 1;

    if (this.requestCounter === 1) {
      this.abns.setBusy(true);
    }
  }

  endRequest(): void {
    this.requestCounter = Math.max(this.requestCounter, 1) - 1;

    if (this.requestCounter === 0) {
      this.abns.setBusy(false);
    }
  }
}

@NgModule({})
export class AjaxBusyIdentifierInterceptorModule {
  static forRoot(): ModuleWithProviders<AjaxBusyIdentifierInterceptorModule> {
    return {
      ngModule: AjaxBusyIdentifierInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AjaxBusyIdentifierInterceptorService,
          multi: true,
        },
      ],
    };
  }
}
