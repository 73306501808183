import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment as env } from '../environments/environment';
import { NotificationService } from './notification.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService) {
    super();
  }

  handleError(error: Error | ErrorEvent | HttpErrorResponse): void {
    let displayMessage = 'An error occurred.';
    if (error instanceof HttpErrorResponse || error instanceof ErrorEvent) {
      if (error.error && error.error.message) {
        displayMessage = error.error.message;
      } else if (
        error.error &&
        error.error.error &&
        error.error.error.message
      ) {
        displayMessage = error.error.error.message;
      } else if (error instanceof HttpErrorResponse && error.statusText) {
        displayMessage = error.statusText;
      }
    } else if ((error as any).originalError) {
      displayMessage = env.production ? 'Authentication Error' : error.message;
    }

    try {
      if (!env.production) {
        displayMessage += ' See console for details.';
      }
      this.notificationsService.error(displayMessage);
    } catch (ex) {}

    super.handleError(error);
  }
}
