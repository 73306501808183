import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { logIt } from '../utils';

export interface IBreakPoint {
  XSmall: boolean;
  Small: boolean;
  Medium: boolean;
  Large: boolean;
  XLarge: boolean;
  Handset: boolean;
  Tablet: boolean;
  Web: boolean;
  HandsetPortrait: boolean;
  TabletPortrait: boolean;
  WebPortrait: boolean;
  HandsetLandscape: boolean;
  TabletLandscape: boolean;
  WebLandscape: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MediaSizeService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public breakPoints(): Observable<IBreakPoint> {
    return this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
      ])
      .pipe(
        map((results) => {
          const keys = Object.keys(results.breakpoints).sort();
          const res: IBreakPoint = {
            XSmall: results.breakpoints[keys[0]],
            Small: results.breakpoints[keys[7]],
            Medium: results.breakpoints[keys[9]],
            Large: results.breakpoints[keys[3]],
            XLarge: results.breakpoints[keys[5]],
            Handset:
              results.breakpoints[keys[1]] || results.breakpoints[keys[2]],
            Tablet:
              results.breakpoints[keys[6]] || results.breakpoints[keys[10]],
            Web: results.breakpoints[keys[8]],
            HandsetPortrait: results.breakpoints[keys[1]],
            TabletPortrait: results.breakpoints[keys[6]],
            WebPortrait: results.breakpoints[keys[8]],
            HandsetLandscape: results.breakpoints[keys[2]],
            TabletLandscape: results.breakpoints[keys[10]],
            WebLandscape: results.breakpoints[keys[4]],
          };
          logIt('info', { breakpoints: res });
          return res;
        }),
        shareReplay(1)
      );
  }
}
