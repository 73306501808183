import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { AngularMaterialModule } from '../angular-material.module';
import { CheckForUpdateService } from '../check-for-update.service';
import { AjaxBusyIdentifierInterceptorModule } from './busy-identifier/ajax-busy-identifier-interceptor.service';
import { MediaSizeService } from './media-size.service';
import { SafePipe } from './safe.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { IsArrayPipe } from './is-array.pipe';
import { TaskStatusComponent } from './task-status/task-status.component';
import { AgGridDatePickerComponent } from './ag-grid-date-picker/ag-grid-date-picker.component';
import { ParInputFileUploadComponent } from './par-input-file-upload/par-input-file-upload.component';

@NgModule({
  declarations: [
    SafePipe,
    Nl2brPipe,
    IsArrayPipe,
    TaskStatusComponent,
    AgGridDatePickerComponent,
    ParInputFileUploadComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.forRoot(),
  ],
  exports: [
    SafePipe,
    Nl2brPipe,
    IsArrayPipe,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AjaxBusyIdentifierInterceptorModule,
    TaskStatusComponent,
    AgGridDatePickerComponent,
    AgGridModule,
    ParInputFileUploadComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [MediaSizeService, CheckForUpdateService],
    };
  }
}
